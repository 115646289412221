<template>
  <!--   报表开发-->
  <div
    class="easyReportDisplayBox contractManageBox customMainTableBox"
  >
    <!-- 搜索条件 start -->
    <div v-if="isShowList" class="card-shadow-back content-box">
      <search-module ref="search" @searchBtn="searchBtn" @reset="reset">
        <el-form
          :inline="true"
          ref="searchForm"
          :model="searchForm"
          class="demo-form-inline"
          :label-width="searchFormItemWidth">
          <el-form-item label="UID编号" prop="uid" v-show="false">
            <el-input
              clearable
              v-model.trim="searchForm.uid"
              @keyup.enter.native="searchBtn"
            ></el-input>
          </el-form-item>

          <el-form-item
            :label="item.text"
            v-for="(item, index) in tableQueryParams"
            :key="index"
            :prop="item.name"
          >
            <!--
            queryType: '', // 查询类型  1文本  2 下拉框  3 下拉框多选  4 日期：年月日  5 日期：年月  6 日期：年  7 日期：月  8 日期：日期范围
            -->
            <el-input v-if="item.queryType == 1" v-model="item.value" clearable @keyup.enter.native="searchBtn"/>
            <el-select
              v-if="item.queryType == 2"
              v-model="item.value"
              @change="searchBtn"
            >
              <el-option
                v-for="(itemb, itemIndex) in setTableQueryOptions(item)"
                v-bind:key="itemIndex"
                :label="itemb.attribute"
                :value="itemb.code"
              ></el-option>
            </el-select>
            <el-select
              v-if="item.queryType == 3"
              v-model="item.value"
              @change="searchBtn"
              multiple
            >
              <el-option
                v-for="(itemb, itemIndex) in setTableQueryOptions(item)"
                v-bind:key="itemIndex"
                :label="itemb.attribute"
                :value="itemb.code"
              ></el-option>
            </el-select>
            <el-date-picker
              v-else-if="item.queryType == 4"
              clearable
              value-format="yyyy-MM-dd"
              v-model="item.value"
              type="date"
            ></el-date-picker>
            <el-date-picker
              v-else-if="item.queryType == 5"
              clearable
              value-format="yyyy-MM"
              v-model="item.value"
              type="month"
            ></el-date-picker>
            <el-date-picker
              v-else-if="item.queryType == 6"
              clearable
              value-format="yyyy"
              v-model="item.value"
              type="year"
            ></el-date-picker>
            <el-select
              v-if="item.queryType == 7"
              v-model="item.value"
              @change="searchBtn"
            >
              <el-option
                v-for="(mon, monIndex) in monthOptions"
                v-bind:key="mon"
                :label="mon.label"
                :value="mon.value"
              ></el-option>
            </el-select>
            <el-date-picker
              v-else-if="item.queryType == 8"
              clearable
              value-format="yyyy-MM-dd"
              v-model="item.value"
              type="daterange"
            ></el-date-picker>
          </el-form-item>
        </el-form>
      </search-module>
      <!-- 搜索条件 end -->
      <!-- table start -->
      <!--    <div class="card-shadow-back content-box" v-if="isShowList">-->
      <div class="roleContent">
        <div class="handle-box">
          <el-button type="primary" @click="exportList()">导出</el-button>
        </div>
        <el-table v-if="tableMultipleHead && tableMultipleHead.length"
          class="max-table table-reset"
          border
          :max-height="maxHeight"
          ref="tableRef"
          :data="tableDataList"
          @selection-change="handleSelectionChange"
          :header-cell-style="{ background: '#f0f2f5' }"
        >
          <el-table-column type="index" prop="" label="序号" min-width="60" align="center"/>
          <el-table-column :prop="item.columnKey" :label="item.header" :align="tAlign(item.placeFlag)"
                           v-for="(item, index) in tableMultipleHead" :key="index" min-width="140" show-overflow-tooltip>
            <table-column v-if="item.childList && item.childList.length" :multipleData="item" :ak="1"></table-column>

<!--            <template v-else slot-scope="scope">-->
<!--              <span>qqq{{scope.row}}</span>-->
<!--              <router-link-->
<!--                v-if="item.url && item.url != null && item.url !== ''"-->
<!--                style="color: blue"-->
<!--                :to="-->
<!--                  {-->
<!--                    path: '/easyReport/easyReportDetailedDisplay?' + item.url.split('?')[1],-->
<!--                    query: { datapid: scope.row['datapid']}-->
<!--                  }"-->
<!--              >{{ scope.row[item.columnKey]}}</router-link>-->
<!--              <span v-else>{{scope.row[item.columnKey]}}</span>-->

<!--            </template>-->
          </el-table-column>
        </el-table>



        <el-table v-else
          class="max-table table-reset"
          border
          :max-height="maxHeight"
          ref="tableRef"
          :data="tableDataList"
          @selection-change="handleSelectionChange"
          :header-cell-style="{ background: '#f0f2f5' }"
        >
          <el-table-column
            type="index"
            prop=""
            label="序号"
            min-width="60"
            align="center"
            fixed="left"
          />
          <el-table-column
            :label="item.header"
            :align="tAlign(item.placeFlag)"
            v-for="(item, index) in tableHead"
            :key="index"
            min-width="140"
            show-overflow-tooltip
            :fixed="item.isFixed ? 'left' : false"
          >
            <template scope="scope">
              <span v-if="item.url == null || item.url === ''">{{
                  scope.row[item.key]
                }}</span>
              <router-link
                v-else-if="item.url != null && item.url !== ''"
                style="color: blue"
                :to="
                  {
                    path: '/easyReport/easyReportDetailedDisplay?' + item.url.split('?')[1],
                    query: {
                      datapid: scope.row['datapid'],
                      querySubParams: JSON.stringify(tableQueryParams),
                      mainTableData: JSON.stringify(scope.row)
                    }
                  }"
              >{{ scope.row[item.key] }}
              </router-link>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          :current-page.sync="page.current"
          :total="page.total"
          @current-change="curChange"
          :page-size.sync="page.size"
          @size-change="curChange"
          :page-sizes="[20, 50, 100]"
          layout="total,sizes,prev,pager,next,jumper"
        ></el-pagination>
        <div style="clear: both"></div>
      </div>
    </div>
    <!-- table end -->
    <!-- 编辑查看弹窗 dialog -->
    <easyReportEdit
      :types="types"
      ref="easyReportEdit"
      @dialogBoolHide="dialogBoolHide"
      :dialogFormVisible="dialogFormVisible"
      :lockObject="lockObject"
      :timeStampAdd="timeStampAdd"
      :getBpmnNodeArr="getBpmnNodeArr"
      :historyFlowListAry="historyFlowListAry"
      :typeLookBool="typeLookBool"
      :processInstanceId="processInstanceId"
    ></easyReportEdit>
  </div>
</template>

<script>
import {exportEasyReport, getOrderById, searchReportDisplayPage} from '@/service/easyReport.js';
import {mapGetters} from "vuex";
import easyReportEdit from "@/views/easyReport/edit";
import tableColumn from '@/views/easyReport/easyReportDisplay/tableColumn';

export default {
  name: "easyReportDisplay",
  props: {
    isShowList: {
      // 是否显示列表
      default: true,
    },
  },
  components: {
    easyReportEdit,
    tableColumn
  },
  data() {
    return {
      activeNames: ["0"], //默认展开搜索栏
      //搜索form
      searchForm: {
        uid: "", //UID编号
        orderType: "", //单据类型
        approvalStatus: "", //审批状态
        approvalStatuses: [], //审批状态表
        applyBy: "", //申请人
        applyName: "", //
        applyOrgId: "", //申请人所属组织
        applyOrgName: "", //
        enterpriseId: "", //公司ID
        enterpriseName: "", //
        name: "", //
        searchStartDate: "", //开始时间
        searchEndDate: "", //结束时间
        queryParams: "", //查询条件
        queryTime: [],
        // input: [],
        // name:{
        //     0:1,
        //     1:2
        // }
      },
      //分页数据
      page: {
        size: 20,
        current: 1,
        total: 0,
      },
      types: "look",
      dialogFormVisible: false, //弹窗显示隐藏
      searchFormItemWidth: "160",
      maxHeight: "500",
      metaReportName: "", //报表名称
      tableDataList: [], //列表数据
      selectList: [],
      lockObject: {}, // 详情基本信息
      timeStampAdd: 0, //点击添加 出发监听 申请人币种用
      getBpmnNodeArr: [], // 审批流
      historyFlowListAry: [], // 审批历史
      tableHead: [], //表头数据
      tableMultipleHead: [], //多表头数据
      tableQueryParams: [], //查询数据
      tableQueryOptions: [], //查询下拉框
      typeLookBool: true,
      processInstanceId: "",
      easyReportUid: '',
      monthOptions: [
        {label: '1', value: '1'},
        {label: '2', value: '2'},
        {label: '3', value: '3'},
        {label: '4', value: '4'},
        {label: '5', value: '5'},
        {label: '6', value: '6'},
        {label: '7', value: '7'},
        {label: '8', value: '8'},
        {label: '9', value: '9'},
        {label: '10', value: '10'},
        {label: '11', value: '11'},
        {label: '12', value: '12'},
      ]
    };
  },
  computed: {
    ...mapGetters(["permission"]), //勾取当前用户权限资源
  },
  created() {
  },
  mounted() {
    // if (this.isShowList) {
    //   this.heightAdjustLoad();
    // }
  },
  watch: {
    "$route.query.easyReportUid": {
      immediate: true, // immediate==true选项可以开启首次赋值监听
      handler(newVal) {
        if (newVal) {
          this.tableQueryParams = []
          // searchForm
          this.easyReportUid = newVal
          this.searchForm= {
              uid: newVal, //UID编号
              orderType: "", //单据类型
              approvalStatus: "", //审批状态
              approvalStatuses: [], //审批状态表
              applyBy: "", //申请人
              applyName: "", //
              applyOrgId: "", //申请人所属组织
              applyOrgName: "", //
              enterpriseId: "", //公司ID
              enterpriseName: "", //
              name: "", //
              searchStartDate: "", //开始时间
              searchEndDate: "", //结束时间
              queryParams: "", //查询条件
              queryTime: [],
          },
          this.onLoad(this.searchForm, this.page); //初始化列表
          // this.showFile()
        }
      },
    },
  },
  methods: {
    tAlign(val) {
      if (val == 1) {
        return 'left'
      } else if (val == 2) {
        return 'center'
      } else if (val == 3) {
        return 'right'
      } else {
        return 'left'
      }
    },
    // 导出
    exportList() {
      let from = JSON.parse(JSON.stringify(this.searchForm));
      if (from.queryTime && from.queryTime.length > 0) {
        this.$set(from, "searchStartDate", from.queryTime[0]);
        this.$set(from, "searchEndDate", from.queryTime[1]);
        delete from.queryTime;
      } else {
        from.searchStartDate = "";
        from.searchEndDate = "";
      }
      exportEasyReport(from, this.page).then((res) => {
        this.exportBlod(res, this.metaReportName); //调用导出方法
      });
    },

    // 新增
    add() {
      this.lockObject = {};
      this.getBpmnNodeArr = [];
      this.historyFlowListAry = [];
      this.typeLookBool = false;
      this.$refs.easyReportEdit.activeName = "one";
      this.types = "add"; //types = look查看 add 新增  edit编辑
      this.timeStampAdd = new Date().getTime();
      this.dialogFormVisible = true;
    },
    // 查询修改
    editRead(row, type) {
      this.$refs.easyReportEdit.activeName = "one";
      this.getOrderById(row, type);
    },
    onLoad(searchForm = {}, page = {size: 20, current: 1}) {
      //页面查询列表
      if (searchForm.queryTime) {
        searchForm.searchStartDate = searchForm.queryTime[0];
        searchForm.searchEndDate = searchForm.queryTime[1];
      } else {
        searchForm.searchStartDate = "";
        searchForm.searchEndDate = "";
      }
      const loading = this.$loading({
        lock: true,
        text: "加载中,请稍后。。。",
        spinner: "el-icon-loading",
      });
      searchReportDisplayPage({
        filter: searchForm, 
        paging: page
      }).then((res) => {
          loading.close();
          if (res.success) {
            const data = res.data;
            this.page.size = data.size;
            this.page.current = data.current;
            this.page.total = data.total;
            this.metaReportName = data.metaReportName;
            this.tableDataList = data.tableData;
            this.tableHead = data.tableHead;
            this.tableMultipleHead = data.tableMultipleHead;
            this.tableQueryParams = data.tableQueryParams;
            this.tableQueryOptions = data.tableQueryOptions;
            this.$nextTick(() => {
              this.$refs.tableRef.doLayout();
              this.$forceUpdate()
            });
          }
        })
        .catch((e) => {
          loading.close();
        });
    },
    // 获取报表开发查看明细
    async getOrderById(row, type) {
      this.lockObject = {};
      this.getBpmnNodeArr = [];
      this.historyFlowListAry = [];
      this.types = type;
      let params = {
        id: row.id,
      };
      let res = await getOrderById(params);
      if (res.success) {
        this.lockObject = res.data;
        //let tableTitle = ["报表开发"];
        if ("edit" === type) {
          this.$refs.easyReportEdit.activeName = "one";
          this.typeLookBool = false; //控制input是否可以编辑
        } else {
          if (
            this.lockObject.approvalStatus === "dtj" ||
            this.lockObject.approvalStatus === "wan" ||
            this.lockObject.approvalStatus === "tui" ||
            this.lockObject.approvalStatus === "fei"
          ) {
            this.$refs.easyReportEdit.activeName = "one";
          } else {
            this.$refs.easyReportEdit.activeName = "two";
          }
          this.typeLookBool = true; //控制input是否可以编辑
        }

        this.processInstanceId = this.lockObject.processInstanceId; //工作流实例ID

        await this.searchDetailByOrderId(row);
        this.dialogFormVisible = true;
      }
    },
    // 获取报表明细
    async searchDetailByOrderId(row) {
      this.$set(this.lockObject, "detailList", row.metaColumns);
    },
    //关闭遮罩层和子类调用函数
    dialogBoolHide() {
      this.$refs.searchForm.resetFields(); //初始化搜索表单
      this.onLoad(this.searchForm);
      this.dialogFormVisible = false;
    },
    searchBtn() {
      //搜索
      this.page.current = 1;
      this.page.total = 0;
      this.searchForm.queryParams = JSON.stringify(this.tableQueryParams);
      this.onLoad(this.searchForm, this.page);
    },
    curChange() {
      //点击分页
      this.onLoad(this.searchForm, this.page);
    },
    //选择
    handleSelectionChange(row) {
      this.selectList = row;
    },
    //查询添加回车事件
    handleLogin() {
      this.searchBtn();
    },
    //清空
    reset() {
      this.$refs["searchForm"].resetFields();
      this.searchForm.uid = this.easyReportUid
      this.tableQueryParams.forEach((obj) => {
        obj.value = "";
      });
      this.searchBtn();
    },
    //控制高度
    changeCollapse() {
      setTimeout(this.heightAdjustLoad, 300);
    },
    setTableQueryOptions(item) {
      let arr = []
      this.tableQueryOptions.forEach(v => {
        if(v.optionsName == item.name) {
          arr = v.optionsValue
        }
      })
      return arr
    },
  },
};
</script>

<style scoped lang="scss">
// /deep/ .el-table th.gutter {
//   width: 0 !important;
//   display: table-cell !important;
// }
 .el-table th.gutter {
  width: 0 !important;
  display: table-cell !important;
}

  .easyReportDisplayBox .table-reset.el-table th.is-leaf {
    border-top: 1px solid #ddd;
  }

</style>
