<template>
  <div class="tableColumn">
      <el-table-column :prop="item.columnKey" :label="item.header" :align="tAlign(item.placeFlag)"
                       v-for="(item, index) in multipleData.childList" :key="ak+ 'tc' +index" min-width="140">
        <table-column v-if="item.childList && item.childList.length" :tableColumn="item" :ak="ak+1"></table-column>
<!--        <template v-else scope="scope">-->
<!--          <router-link-->
<!--            v-if="item.url && item.url != null && item.url !== ''"-->
<!--            style="color: blue"-->
<!--            :to="-->
<!--                  {-->
<!--                    path: '/easyReport/easyReportDetailedDisplay?' + item.url.split('?')[1],-->
<!--                    query: { datapid: scope.row['datapid']}-->
<!--                  }"-->
<!--          >{{ scope.row[item.columnKey]}}</router-link>-->
<!--          <span v-else>{{scope.row[item.columnKey]}}</span>-->
<!--        </template>-->
      </el-table-column>


  </div>
</template>

<script>
  import tableColumn from '@/views/easyReport/easyReportDisplay/tableColumn';
  export default {
    name: "tableColumn",
    components: {
      tableColumn
    },
    props: {
      multipleData: {
        type: Object
      },
      ak: {
        type: Number
      }
    },
    created() {
      console.log(this.multipleData);
    },
    methods: {
      tAlign(val) {
        if (val == 1) {
          return 'left'
        } else if (val == 2) {
          return 'center'
        } else if (val == 3) {
          return 'right'
        } else {
          return 'left'
        }
      },
    }
  }
</script>

<style>
  .tableColumn .table-reset.el-table th.is-leaf {
    border-top: 1px solid #ddd;
  }
</style>
